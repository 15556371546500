<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="ID" v-bind="formItemLayout" v-show="mode=='update'">
        <a-input v-model="form.id" v-decorator="['id']" disabled>
        </a-input>
      </a-form-model-item>

      <a-form-model-item label="行业分类" v-bind="formItemLayout"  property="cateId">
        <a-select :options="cateList" v-model="form.cateId"
                  v-decorator="['cateId',{rules:[{required:true,message:'行业分类不能为空'}]}]"></a-select>
      </a-form-model-item>
      <a-form-model-item
          label="状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="行业名称" v-bind="formItemLayout">
        <a-input v-model="form.industryName" v-decorator="['industryName']" placeholder="行业名称，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="排序序号" v-bind="formItemLayout">
        <a-input-number v-model="form.sortNumber" v-decorator="['sortNumber']" placeholder="排序序号，越大越优先">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifySysIndustryModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      cateList: [],
      rules: {
        dataName: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.$postJson('/category/cateList', {pageSize: 100,type:6}).then(res => {
      this.cateList = ((res.data && res.data.records) || []).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    })
  },
  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改：" + record.industryName;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增"
        this.form = {
          type: 1,
          status: 1
        }
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          if (!this.form.cateId) {
            this.$message.warning("行业分类不能为空");
            return;
          }
          this.confirmLoading = true;
          this.$postJson("sysIndustry/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
